import { Dispatch, SetStateAction } from "react";
import Slider from "react-slick";

import { useCheckIsMobile } from "@sellernote/_shared/src/utils/common/hook";

import { NextArrow, PrevArrow } from "../Arrows";
import Styled from "../index.styles";
import RateItem from "./RateItem";
import useGetHeroSectionRateList from "./useGetHeroSectionRateList";

function DiscountRateContent({
  setAbTestType,
}: {
  setAbTestType: Dispatch<SetStateAction<string>>;
}) {
  const { isMobile } = useCheckIsMobile();

  const sliderSettings = (() => {
    {
      return {
        dots: false,
        infinite: false,
        slidesToShow: isMobile ? 2 : 4,
        slidesToScroll: 1,
        autoplay: false,
        easing: "ease-in",
        rows: 1,
        arrows: !isMobile,
        prevArrow: <PrevArrow />,
        nextArrow: <NextArrow />,
      };
    }
  })();

  const rateList = useGetHeroSectionRateList({ setAbTestType });

  if (!rateList) {
    return null;
  }

  return (
    <>
      <Styled.discountRateContent>
        <Styled.rateList>
          <Slider {...sliderSettings}>
            {rateList.map((v) => {
              if (!v) return null;

              return <RateItem data={v} key={v.scheduleId} />;
            })}

            {/* 모바일에서 마지막 섹션 옆의 여백을 위해, 빈 슬라이드 추가 */}
            {isMobile && <div style={{ width: "290px" }}></div>}
          </Slider>
        </Styled.rateList>

        <p className="desc">
          *위 운임은 확정운임이 아니며, 물류 시황이나 구체적인 운송계획에 따라
          변동될 수 있습니다.
        </p>
      </Styled.discountRateContent>
    </>
  );
}

export default DiscountRateContent;
