import {
  useAppMutation,
  useAppQueryWithQueryKeyFactory,
} from "../../services/query";
import { ResponseFailureInfo } from "../../types/common/common";

import {
  GET_HERO_SECTION_SCHEDULE_LIST_RES,
  GET_SCHEDULE_DETAIL_REQ,
  GET_SCHEDULE_DETAIL_REQ_PATH_PARAMS,
  GET_SCHEDULE_DETAIL_RES,
  GET_SCHEDULE_LIST_REQ,
  GET_SCHEDULE_LIST_RES,
} from "../../api-interfaces/shipda-api/schedule";

export const SCHEDULE_QUERY_KEY_GEN = {
  all: () => [{ scope: "forwarding/SCHEDULE_QUERY" }] as const,

  getScheduleList: (
    params?: Omit<GET_SCHEDULE_LIST_REQ, "scheduleIdListForQuote">
  ) =>
    [
      {
        ...SCHEDULE_QUERY_KEY_GEN.all()[0],
        ...params,
        entity: "getScheduleList",
      },
    ] as const,

  getScheduleDetail: (
    params: GET_SCHEDULE_DETAIL_REQ_PATH_PARAMS & GET_SCHEDULE_DETAIL_REQ
  ) =>
    [
      {
        ...SCHEDULE_QUERY_KEY_GEN.all()[0],
        ...params,
        entity: "getScheduleDetail",
      },
    ] as const,

  getHeroSectionScheduleList: () =>
    [
      {
        ...SCHEDULE_QUERY_KEY_GEN.all()[0],
        entity: "getHeroSectionScheduleList",
      },
    ] as const,
};

function useGetScheduleList({
  params: { scheduleIdListForQuote, ...params },
  enabled,
  onSuccess,
}: {
  params: GET_SCHEDULE_LIST_REQ;
  enabled?: boolean;
  onSuccess?: (data: GET_SCHEDULE_LIST_RES) => void;
}) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof SCHEDULE_QUERY_KEY_GEN.getScheduleList>,
    GET_SCHEDULE_LIST_RES
  >({
    queryKey: SCHEDULE_QUERY_KEY_GEN.getScheduleList(params),
    requestOptions: {
      method: "get",
      path: "/vesselSchedule/list",
      apiType: "ShipdaDefaultNew",
      params: {
        scheduleIdListForQuote,
        ...params,
        // scheduleIdListForQuote이 변경된 경우 페칭이 발생하면 안 되기 떄문에 key로 사용하지 않음
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } as any,
    },

    keepPreviousData: true,

    enabled,

    onSuccess,
  });

  return queryResult;
}

function useGetScheduleDetail({
  scheduleId,
  params,
  enabled,
  onSuccess,
  onError,
}: {
  scheduleId: number;
  params: GET_SCHEDULE_DETAIL_REQ;
  enabled: boolean;
  onSuccess?: (data: GET_SCHEDULE_DETAIL_RES) => void;
  onError?: (
    error: ResponseFailureInfo | undefined,
    hideFailureModal: () => void
  ) => void;
}) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof SCHEDULE_QUERY_KEY_GEN.getScheduleDetail>,
    GET_SCHEDULE_DETAIL_RES
  >({
    queryKey: SCHEDULE_QUERY_KEY_GEN.getScheduleDetail({
      scheduleId,
      ...params,
    }),
    requestOptions: {
      method: "get",
      path: `/vesselSchedule/${scheduleId}/detail`,
      apiType: "ShipdaDefaultNew",
      params,
    },

    enabled,

    onSuccess,
    onError,

    hidesLoading: true,
  });

  return queryResult;
}

function useGetHeroSectionScheduleList({
  onError,
}: {
  onError?:
    | ((
        error: ResponseFailureInfo | undefined,
        hideFailureModal: () => void
      ) => void)
    | undefined;
}) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof SCHEDULE_QUERY_KEY_GEN.getHeroSectionScheduleList>,
    GET_HERO_SECTION_SCHEDULE_LIST_RES
  >({
    queryKey: SCHEDULE_QUERY_KEY_GEN.getHeroSectionScheduleList(),
    requestOptions: {
      method: "get",
      path: "/vesselSchedule/list/lowestPrice",
      apiType: "ShipdaDefaultNew",
    },

    onError,
  });

  return queryResult;
}

function useShareSchedule({ scheduleId }: { scheduleId: number }) {
  const mutation = useAppMutation<unknown, unknown>({
    requestOptions: {
      method: "PATCH",
      path: `/vesselSchedule/${scheduleId}/share`,
      apiType: "ShipdaDefaultNew",
    },

    failureModalInfo: {
      customizeMessage: () => ({
        title: "스케줄을 공유하는 중에 오류가 발생했습니다.",
      }),
    },

    hidesLoading: true,
  });

  return mutation;
}

const SCHEDULE_QUERY = {
  useGetScheduleList,
  useGetScheduleDetail,

  useGetHeroSectionScheduleList,

  useShareSchedule,
};
export default SCHEDULE_QUERY;
