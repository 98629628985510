import Image from "next/image";
import Router from "next/router";

import { HeroSectionSchedule } from "@sellernote/_shared/src/types/forwarding/schedule";
import { toFormattedDate } from "@sellernote/_shared/src/utils/common/date";
import { addEventToGTMDataLayer } from "@sellernote/_shared/src/utils/common/gtm";
import { toThousandUnitFormat } from "@sellernote/_shared/src/utils/common/number";
import { replaceEmptyToDash } from "@sellernote/_shared/src/utils/common/string";
import { getBaseSupply } from "@sellernote/_shared/src/utils/forwarding/common";
import Badge from "@sellernote/_sds-v2/src/components/Badge";
import { COLOR, TEXT_COLOR } from "@sellernote/_sds-v2/src/styles/colors";

import { formatPort } from "./utils";

import Styled from "../index.styles";

export default function RateItem({
  data,
}: {
  data: HeroSectionSchedule | undefined;
}) {
  const {
    scheduleId,
    freightType,
    polPortKrName,
    polPortEnName,
    polCountry,
    pol,
    podPortKrName,
    podPortEnName,
    podCountry,
    pod,
    logoUrl,
    carrier,
    etd,
    eta,
    originalPrice,
    cost,
    firstDiscountPrice,
  } = data || {};

  const canFirstDiscount = Boolean(firstDiscountPrice);

  const handleItemClick = () => {
    addEventToGTMDataLayer({
      event: "hero_fare_clicked",
    });

    Router.push(
      `/forwarding/quote?queryStringCase=scheduleSelect&scheduleId=${scheduleId}`
    );
  };

  const baseSupply = getBaseSupply(freightType);

  return (
    <div>
      <Styled.rateItem onClick={handleItemClick}>
        <div className="carrier-logo">
          {logoUrl ? (
            <Image
              src={logoUrl}
              alt={`${carrier} logo`}
              objectFit="contain"
              width={59}
              height={24}
            />
          ) : (
            replaceEmptyToDash(carrier)
          )}
        </div>

        <div className="start-end-container">
          <div className="route-line" />

          <div className="start-end-route">
            <div className="start-point">
              <div className="country-port">
                <Image
                  src={`/assets/images/countryFlag/${polCountry}.png`}
                  alt={`${polCountry} flag`}
                  objectFit="contain"
                  width={24}
                  height={16}
                />

                <span className="port">
                  {formatPort({
                    portKrName: polPortKrName,
                    portEnName: polPortEnName,
                    code: pol,
                  })}
                </span>
              </div>

              <span className="date departure-date">
                {toFormattedDate(etd, "YY-MM-DD")} (ETD)
              </span>
            </div>

            <div className="end-point">
              <div className="country-port">
                <Image
                  src={`/assets/images/countryFlag/${podCountry}.png`}
                  alt={`${podCountry} flag`}
                  objectFit="contain"
                  width={24}
                  height={16}
                />

                <span className="port">
                  {formatPort({
                    portKrName: podPortKrName,
                    portEnName: podPortEnName,
                    code: pod,
                  })}
                </span>
              </div>
              <span className="date arrival-date">
                {toFormattedDate(eta, "YY-MM-DD")} (ETA)
              </span>
            </div>
          </div>
        </div>

        <div className="price-container">
          {canFirstDiscount && (
            <div className="price">
              <Badge
                borderType="filled"
                colorInfo={{
                  background: COLOR.point_400,
                  text: TEXT_COLOR.white_1,
                }}
                label="첫 운송 할인"
                className="custom-discount-badge"
              />

              <del>₩{toThousandUnitFormat(originalPrice)}</del>
            </div>
          )}

          <div className="base-supply-price">
            <span className="base-supply">{baseSupply}</span>

            <span className="discounted-price">
              ₩{toThousandUnitFormat(cost)}~
            </span>
          </div>
        </div>
      </Styled.rateItem>
    </div>
  );
}
